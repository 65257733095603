<template>
    <div class="background">
        <keno-result-top :numbers="stateData.roundResult.resultTicket"
                         class="result__top"
        />

        <keno-bets-table :result-ticket="stateData.roundResult.resultTicket"
                         :result-tickets="stateData.roundResult.resultTickets"
                         class="bets__table"
        />

        <keno-numbers :result-ticket="stateData.roundResult.resultTicket"
                      :result-tickets="stateData.roundResult.resultTickets"
                      class="numbers"
        />
    </div>
</template>

<script>
import KenoResultTop from "./KenoResultTop";
import KenoBetsTable from "./KenoBetsTable";
import KenoNumbers from "./KenoNumbers";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        resultTickets: [{
                            numbers: null,
                            bet: null,
                            matchCount: null,
                            totalCount: null,
                            matchNumbers: null,
                            coefficient: null,
                            win: null,
                        }],
                        resultTicket: null,
                    },
                    denom: null
                }
            }
        },
    },
    data() {
        return {
            default: {
                width: 1470,
                height: 806,
            }
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 806);
        this.$store.dispatch('workspace/setWidth', 1470);
    },
    components: {
        'keno-result-top': KenoResultTop,
        'keno-bets-table': KenoBetsTable,
        'keno-numbers': KenoNumbers,
    },
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/keno/keno/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1470px;
    height: 806px;
}

.result__top {
    position: relative;
    top: 32px;
    left: 150px;
}

.bets__table {
    position: relative;
    top: 165px;
    left: 857px;
}

.numbers {
    position: relative;
    top: 140px;
    left: 55px;
}
</style>
