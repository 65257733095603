<template>
    <div>
        <div v-for="(value, key) in numbers"
             :key="key"

             :style="ballPosition(key)"
             class="ball"
        >
            <img :src="ballImage"/>

            <span class="ball__number">
                {{ value }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        numbers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            ball: 63
        }
    },
    computed: {
        ballImage() {
            return require('@/assets/games/keno/keno/ball.png');
        },
    },
    methods: {
        ballPosition(number) {
            let left = number * this.ball;

            return {
                left: `${left}px`,
                top: `0px`,
            }
        }
    }
}
</script>

<style scoped>
.ball {
    position: absolute;
}

.ball__number {
    font-weight: bold;
    font-size: 32px;
    color: black;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}
</style>
