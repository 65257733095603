<template>
    <div>
        <div v-for="(number, index) in numbers"
             :key="index"

             :style="getNumbersPosition(number)"
             class="number"
        >
            <img v-if="isNumberInWinTicket(number)"
                 :src="matchNumberImage"/>

            <img v-else-if="isNumberInMatch(number)"
                 :src="resultNumberImage"/>

            <img v-else-if="isNumberInSelected(number)"
                 :src="selectedNumberImage"/>

            <img v-else
                 :src="defaultNumberImage"/>

            <span class="numbers__text"
            >
                {{ number }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resultTickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    numbers: null,
                    bet: null,
                    matchCount: null,
                    totalCount: null,
                    matchNumbers: null,
                    coefficient: null,
                    win: null,
                }]
            }
        },
        resultTicket: {
            type: Array,
            required: true,
            default: null
        }
    },
    data() {
        return {
            number: {
                width: 69,
                height: 74
            },
            margin: {
                top: 5,
                left: 5,
            }
        }
    },
    computed: {
        matchNumberImage() {
            return require('@/assets/games/keno/keno/block_orange.png');
        },
        selectedNumberImage() {
            return require('@/assets/games/keno/keno/block_graygreen.png');
        },
        resultNumberImage() {
            return require('@/assets/games/keno/keno/block_yellow.png');
        },
        defaultNumberImage() {
            return require('@/assets/games/keno/keno/block_green.png');
        },
        ticketNumbers() {
            let numbers = new Set();

            for (let i = 0; i < this.resultTickets.length; i++) {
                let ticket = this.resultTickets[i];

                for (let j = 0; j < ticket.numbers.length; j++) {
                    numbers.add(ticket.numbers[j]);
                }
            }

            return Array.from(numbers);
        },
        matchNumbers() {
            let numbers = new Set();

            for (let i = 0; i < this.resultTickets.length; i++) {
                let ticket = this.resultTickets[i];

                for (let j = 0; j < ticket.matchNumbers.length; j++) {
                    numbers.add(ticket.matchNumbers[j]);
                }
            }

            return Array.from(numbers);
        },
        numbers() {
            return this.range(1, 81);
        }
    },
    methods: {
        range(start, end) {
            return Array.from({length: (end - start)}, (v, k) => k + start);
        },
        getNumbersPosition(number) {
            number -= 1;

            let one = number % 10;
            let dozen = Math.floor(number / 10);

            let left = (this.number.width + this.margin.left) * one;
            let top = (this.number.height + this.margin.top) * dozen;

            return {
                left: `${left}px`,
                top: `${top}px`,
            }
        },
        isNumberInSelected(number) { // Номер выбран, но не сыграл
            return this.ticketNumbers.includes(number);
        },
        isNumberInWinTicket(number) { // Номер выигрышный
            return this.resultTicket.includes(number) && this.matchNumbers.includes(number);
        },
        isNumberInMatch(number) { // Номер есть в совпавших
            return this.resultTicket.includes(number);
        },
    }
}
</script>

<style scoped>
.numbers__text {
    font-size: 36px;
    color: #1c3020;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.number {
    position: absolute;
    background-color: transparent;
}
</style>
