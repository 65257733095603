<template>
    <div>
        <div v-for="(ticket, index) in resultTickets"
             :key="index"

             :style="rowPosition(index)"
             style="position: relative"
        >
            <img v-if="ticket.win > 0"
                 :src="rowWinTicketBordersImage"

                 style="position: absolute"
            />

            <!-- Ставка -->
            <div :style="stakePosition()"
                 style="position: absolute"
            >
                <img :src="rowStakeBordersImage"/>
                <span class="table__text">
                    {{ fromCents(ticket.bet) }}
                </span>
            </div>

            <!-- Номера -->
            <div v-for="(number, numberIndex) in numbers"
                 :key="numberIndex"

                 :style="ballPosition(numberIndex)"
                 style="position: absolute"
            >
                <img :src="isNumberExists(ticket.numbers[number]) ? rowWinNumberBackgroundImage : rowNumberBackgroundImage"/>

                <span class="table__text">
                    {{ ticket.numbers[number] }}
                </span>
            </div>

            <!-- Количество -->
            <div :style="countPosition()"
                 style="position: absolute"
            >
                <img :src="rowCountBordersImage"/>
                <span class="table__text">
                    {{ ticket.matchCount }}/{{ ticket.totalCount }}
                </span>
            </div>

            <!-- Выигрыш -->
            <div :style="winPosition()"
                 style="position: absolute"
            >
                <img :src="rowWinBorderImage"/>
                <span class="table__text">
                    {{ fromCents(ticket.win) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {fromCents} from "../../../../../helpers/balanceConverter";

export default {
    props: {
        resultTickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    numbers: null,
                    bet: null,
                    matchCount: null,
                    totalCount: null,
                    matchNumbers: null,
                    coefficient: null,
                    win: null,
                }]
            }
        },
        resultTicket: {
            type: Array,
            required: true,
            default: null
        }
    },
    data() {
        return {
            stake: {
                width: 111,
                height: 33,
            },
            row: {
                height: 35
            },
            ball: {
                width: 27
            },
            count: {
                width: 61
            },
            margin: {
                top: 3,
                left: 2,
            }
        }
    },
    computed: {
        rowStakeBordersImage() {
            return require('@/assets/games/keno/keno/table/bet_table_line_stake.png');
        },
        rowCountBordersImage() {
            return require('@/assets/games/keno/keno/table/bet_table_line_count.png');
        },
        rowWinBorderImage() {
            return require('@/assets/games/keno/keno/table/bet_table_line_win.png');
        },
        rowWinTicketBordersImage() {
            return require('@/assets/games/keno/keno/bet_table_line_win.png');
        },
        rowNumberBackgroundImage() {
            return require('@/assets/games/keno/keno/bets_table.png');
        },
        rowWinNumberBackgroundImage() {
            return require('@/assets/games/keno/keno/bets_table_win.png');
        },
        numbers() {
            return this.range(0, 10);
        }
    },
    methods: {
        fromCents,
        range(start, end) {
            return Array.from({length: (end - start)}, (v, k) => k + start);
        },
        rowPosition(ticketNumber) {
            let top = ticketNumber * this.row.height + this.margin.top;

            return {
                left: '0px',
                top: `${top}px`
            }
        },
        stakePosition() {
            return {
                left: '0px',
                top: '0px'
            }
        },
        ballPosition(ballNumber) {
            let left = this.stake.width + (ballNumber + 1) * this.margin.left + ballNumber * this.ball.width;

            return {
                left: `${left}px`,
                top: '0px'
            }
        },
        countPosition() {
            let left = this.stake.width + 10 * (this.margin.left + this.ball.width) + this.margin.left;

            return {
                left: `${left}px`,
                top: '0px'
            }
        },
        winPosition() {
            let left = this.stake.width + 10 * (this.margin.left + this.ball.width) + 2 * this.margin.left + this.count.width;

            return {
                left: `${left}px`,
                top: '0px'
            }
        },
        isNumberExists(number) {
            return this.resultTicket.includes(number);
        }
    }
}
</script>

<style scoped>
.table__text {
    font-size: 18px;
    color: #2b3829;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}
</style>
